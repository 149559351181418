import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { agencies } from "../data";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import UserProfileDropdown from "./UserProfileDown";
import Cards from "./Cards";
import Pagination from "./Pagination";
import QRCodeCards from "./QRCodeCards";
const negeriDropdown = [
  "Kuala Lumpur",
  "Labuan",
  "Putrajaya",
  "Johor",
  "Perlis",
  "Kedah",
  "Pulau Pinang",
  "Perak",
  "Selangor",
  "Melaka",
  "Negeri Sembilan",
  "Pahang",
  "Terengganu",
  "Kelantan",
  "Sabah",
  "Sarawak",
];

const useStyles = makeStyles((theme) => ({
  mainroot: {
    padding: "20px !important",
  },
  containerCenter: {
    justifyContent: "center",
    paddingTop: 20,
    "@media(minWidth: 780px)": {},
  },
  image: {
    width: theme.spacing(15),
    margin: theme.spacing(2),
  },
  cardContent: {
    justifyContent: "center",
    "@media(minWidth: 780px)": {},
  },
  typography: {
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    textAlign: "center",
  },
  gridRoot: {
    minHeight: "380px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
  },
  root: {
    border: "1px solid #00ff00",

    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",
    display: "flex",
    "@media(minWidth: 780px)": {
      flexDirection: "column",
      alignItems: "center",
      // display: ""
    },
  },
  media: {
    paddingTop: "56.25%",
    backgroundSize: "contain",
  },
  inputsearch: {
    flex: 1,
    width: "100%",
    "@media(minWidth: 780px)": {
      width: "100%",
    },
  },
  dropdown: {
    padding: 20,
    // '@media(minWidth: 780px)': {
    //   flexDirection: "row-reverse"
    // }
  },
  appBarSpacer: theme.mixins.toolbar,
  cardFlow: {
    justify: "center",
    alignItems: "stretch",
    "@media(minWidth: 780px)": {
      justifyContent: "flex-end",
      alignItems: "flex-start",
      flexDirection: "row-reverse",
    },
  },
  item: {
    margin: theme.spacing(1),
    "@media(minWidth:780px)": {},
  },
  flex: {
    flex: 1,
  },
  lefticon: {
    marginLeft: theme.spacing(4),
  },
  containerButton: {
    // justifyContent: "center",
    paddingTop: 20,
    // flexDirection: "column",
    "@media(minWidth: 780px)": {},
  },
  buttons: {
    margin: theme.spacing(1),
  },
}));

interface Isedekah {
  agency: string;
  name: string;
  negeri: string;
  daerah: string;
  image: string;
}

const Form = ({ user, signOut, searchterm, qrcode }: any) => {
  // () => {

  console.log("user is", user, "search is", searchterm);
  let agencies2 = agencies;
  if (searchterm !== "") {
    let searchterm2 = searchterm.toLocaleLowerCase();
    agencies2 = agencies.filter((item) => {
      return item.name.toLocaleLowerCase().includes(searchterm2);
    });
  }

  const classes = useStyles();
  const [state, setState] = React.useState(searchterm); // searchterm
  const [daerahList, setDaerahList] = React.useState([""]);
  const [daerah, setDaerah] = React.useState("");
  const [negeri, setNegeri] = React.useState("");
  const [agencyLists, setAgencyList] = React.useState<Isedekah[]>(agencies2);
  // const [loading, setLoading] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [postPerPage] = React.useState(12);

  React.useEffect(() => {}, []);

  const indexOfLastPost = currentPage * postPerPage;
  const indexOfFirstPost = indexOfLastPost - postPerPage;
  const currentAgencies = agencyLists.slice(indexOfFirstPost, indexOfLastPost);

  const search = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt && evt.target) {
      setState(evt.target.value);
    }
  };

  let browser = navigator.userAgent;
  console.log("browser", browser);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  const filterDaerah = (negeri: string) => {
    let data: string[] = [];

    // if (false) {
    //   console.log("daerahPicker", typeof agencies, negeri);
    // }
    // let filtered = agencies.filter((item) =>
    //   item.negeri.toLocaleLowerCase().includes(negeri.toLocaleLowerCase())
    // );

    // console.log("filtered", filtered);

    // for (let i = 1; i < filtered.length; i++) {
    //   //   data.push(filtered);
    //   console.log("data", data[i]);
    // }

    // console.log("filtered", filtered);
    let daerahPicker = agencies.filter((item) => {
      if (negeri === "") {
        return true;
      } else {
        if (
          item.negeri.toLocaleLowerCase().includes(negeri.toLocaleLowerCase())
        ) {
          data.push(item.daerah);
          return item.daerah;
        }
      }
      return true;
    });

    data = Array.from(new Set(data));
    console.log("data", data);

    setDaerahList(data);
  };

  // const filterData = () => {
  //   let filterConditionName = state;
  //   let filterConditionState = negeri;
  //   let filterConditionDaerah = daerah;

  //   console.log(
  //     filterConditionName,
  //     filterConditionState,
  //     filterConditionDaerah
  //   );
  //   let filteredArray = agencyLists.filter(
  //     (el) =>
  //       el.name === filterConditionName ||
  //       el.daerah === filterConditionDaerah ||
  //       el.negeri === filterConditionState
  //   );

  //   console.log("filterData", filteredArray);
  // };

  const handleSubmit = () => {
    console.log("state", state);
    console.log("negeri", negeri);
    console.log("daerah", daerah);
    let data: Array<Isedekah> = [];

    // filterData();

    if (state.length > 0) {
      if (negeri.length === 0 && daerah.length === 0) {
        data = agencies.filter((item) => {
          return item.name
            .toLocaleLowerCase()
            .includes(state.toLocaleLowerCase());
        });
      } else if (daerah.length > 0 && negeri.length > 0) {
        // console.log("here");
        data = agencies.filter((item) => {
          if (
            item.name.toLocaleLowerCase().includes(state.toLocaleLowerCase()) &&
            item.daerah
              .toLocaleLowerCase()
              .includes(daerah.toLocaleLowerCase()) &&
            item.negeri.toLocaleLowerCase().includes(negeri.toLocaleLowerCase())
          ) {
            return true;
          }
          return false;
        });
      } else if (negeri.length > 0) {
        data = agencies.filter((item) => {
          if (
            item.name.toLocaleLowerCase().includes(state.toLocaleLowerCase()) &&
            item.negeri.toLocaleLowerCase().includes(negeri.toLocaleLowerCase())
          ) {
            return true;
          }
          return false;
        });
      } else if (daerah.length > 0) {
        data = agencies.filter((item) => {
          if (
            item.name.toLocaleLowerCase().includes(state.toLocaleLowerCase()) &&
            item.daerah.toLocaleLowerCase().includes(daerah.toLocaleLowerCase())
          ) {
            return true;
          }
          return false;
        });
      }
    } else if (daerah.length > 0 && negeri.length > 0) {
      data = agencies.filter((item) => {
        if (
          item.name.toLocaleLowerCase().includes(state.toLocaleLowerCase()) &&
          item.daerah
            .toLocaleLowerCase()
            .includes(daerah.toLocaleLowerCase()) &&
          item.negeri.toLocaleLowerCase().includes(negeri.toLocaleLowerCase())
        ) {
          return true;
        }
        return false;
      });
    } else if (negeri.length > 0) {
      data = agencies.filter((item) => {
        return (
          item.name.toLocaleLowerCase().includes(state.toLocaleLowerCase()) &&
          item.negeri.toLocaleLowerCase().includes(negeri.toLocaleLowerCase())
        );
      });
    } else if (daerah.length > 0) {
      data = agencies.filter((item) => {
        return (
          item.name.toLocaleLowerCase().includes(state.toLocaleLowerCase()) &&
          item.daerah.toLocaleLowerCase().includes(daerah.toLocaleLowerCase())
        );
      });
    } else {
      data = agencies;
    }

    console.log("data after Filter", data);

    setAgencyList(data);
  };

  const onDaerahChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let daerah = event.target.value as string;

    setDaerah(daerah);
  };

  const onNegeriChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let negeri = event.target.value as string;

    setNegeri(negeri);
    setDaerah("");
    filterDaerah(negeri);
    // handleSubmit();
  };

  // temp
  // back button
  const ResetButton = () => {
    // window.location.reload();
    setNegeri("");
    setDaerah("");
    setState("");
    setAgencyList(agencies);
    // handleSubmit(null);
  };
  // press enter
  const pressKey = (event: { key: string }) => {
    if (event.key === "Enter") {
      console.log("Enter Key Press");
      handleSubmit();
    }
  };

  return (
    <div>
      <CssBaseline />
      {user.length > 0 ? (
        <AppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" noWrap>
              Tulus i-Sedekah
            </Typography>
            <div className={classes.flex} />
            <div className={classes.lefticon}>
              <UserProfileDropdown user={user} signOut={signOut} />
            </div>
          </Toolbar>
        </AppBar>
      ) : null}

      <main>
        <Container maxWidth="lg">
          {user.length > 0 ? <div className={classes.appBarSpacer} /> : null}

          {!qrcode && (
            <Container>
              <Grid container spacing={2} className={classes.containerCenter}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    id="outlined-basic"
                    label="Cari Nama"
                    variant="outlined"
                    autoComplete="true"
                    placeholder="Cari Daerah dan Negeri"
                    value={state}
                    inputProps={{ "aria-label": "Cari" }}
                    className={classes.inputsearch}
                    onChange={search}
                    onKeyPress={pressKey}
                  />
                </Grid>

                <Grid item xs={12} sm={3} className={classes.item}>
                  <FormControl fullWidth>
                    <FormHelperText>Negeri</FormHelperText>
                    <Select value={negeri} onChange={onNegeriChange}>
                      {negeriDropdown.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <div></div> */}
                <Grid item xs={12} sm={3} className={classes.item}>
                  <FormControl fullWidth>
                    <FormHelperText>Daerah</FormHelperText>
                    <Select value={daerah} onChange={onDaerahChange}>
                      {daerahList.map((item) => (
                        <MenuItem key={item} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {/* </Grid> */}
              </Grid>
              <Grid
                container
                justify="center"
                alignItems="center"
                className={classes.containerButton}
              >
                <Grid item xs={12} sm={6} className={classes.buttons}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={ResetButton}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.buttons}>
                  <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                  >
                    Cari
                  </Button>
                </Grid>
              </Grid>
            </Container>
          )}

          {!qrcode && (
            <Pagination
              postPerpage={postPerPage}
              agenciesTotal={agencyLists.length}
              paginate={paginate}
            />
          )}

          <div>
            {!qrcode && (
              <Cards
                agencyLists={currentAgencies}
                user={user}
                qrcode={qrcode}
              />
            )}
            {qrcode && (
              <QRCodeCards
                agencyLists={agencyLists}
                user={user}
                qrcode={qrcode}
              />
            )}
          </div>
          <div className={classes.appBarSpacer} />
        </Container>
      </main>
    </div>
  );
};

export default Form;
