declare global {
  interface Window {
    firebaseConfig: any;
  }
}

window.firebaseConfig = window.firebaseConfig || {
  apiKey: "AIzaSyBS0CWEfCC6JeWRjuUbXMdd4w0CApKAYx8",
  authDomain: "snapnpay-15d6d.firebaseapp.com",
  databaseURL: "https://snapnpay-15d6d.firebaseio.com",
  projectId: "snapnpay-15d6d",
  storageBucket: "snapnpay-15d6d.appspot.com",
  messagingSenderId: "667601605177",
  appId: "1:667601605177:web:d107ba648e0625d23c73e8",
  measurementId: "G-Y3LY9WEJ63",
};

export const firebaseConfig = window.firebaseConfig;

export const appinfo = {
  name: "SnapNPay i-Sedekah",
};
