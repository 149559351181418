import React from "react";
import { makeStyles } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import Grid from "@material-ui/core/Grid";

interface Props {
  postPerpage: number;
  agenciesTotal: number;
  paginate: (data: number) => void;
}

const useStyles = makeStyles((theme) => ({
  containerCenter: {
    justifyContent: "center",
    paddingTop: 20,
    paddingLeft: "15%",
    paddingBottom: 30,
    padding: 20,

    "@media(minWidth: 600px)": {
      justifyContent: "center",
      paddingTop: 20,
      paddingLeft: "30%",
      paddingBottom: 30,
      // justifyContent:
      // paddingTop: 30,
      // paddingLeft: "10%",
      // justifyContent: "center",
      // paddingTop: 20,
      // padding: 20,
    },
  },
}));

const Paginations = ({ postPerpage, agenciesTotal, paginate }: Props) => {
  const classes = useStyles();
  let pageNumbers: Number[] = [];
  let pages = 0;
  for (let i = 1; i < Math.ceil(agenciesTotal / postPerpage); i++) {
    pageNumbers.push(i);
    pages++;
  }

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    paginate(value);
  };

  return (
    <Grid container spacing={2} className={classes.containerCenter}>
      <Grid item xs={12} sm={6}>
        <Pagination
          count={pages}
          onChange={handleChange}
          size="large"
          siblingCount={0}
          boundaryCount={1}
        />
      </Grid>

      {/* <nav>
        <ul className="pagination   ">
          {pageNumbers.map((item, i) => (
            <li key={i} className="page-item">
              <a href="!#" className="page-link">
                {item}
              </a>
            </li>
          ))}
        </ul>
      </nav> */}
    </Grid>
  );
};

export default Paginations;
