import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardContent,
  CardActionArea,
  makeStyles,
  Typography,
  CardMedia,
} from "@material-ui/core";
import { generateLink } from "./utils";

const useStyles = makeStyles((theme) => ({
  mainroot: {
    padding: "20px !important",
  },
  containerCenter: {
    justifyContent: "center",
    paddingTop: 20,
    "@media(minWidth: 780px)": {},
  },
  image: {
    width: theme.spacing(15),
    margin: theme.spacing(1),
  },
  cardContent: {
    justifyContent: "center",
    maxwidth: "350px",
    maxHeight: "200px",
    "@media(minWidth: 780px)": {
      maxwidth: "350px",
      maxHeight: "200px",
      justifyContent: "center",
    },
  },
  typography: {
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    textAlign: "center",
    margin: "10px",
  },
  gridRoot: {
    height: "360px",
    // minHeight: "350px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    "@media(minWidth: 780px)": {
      // minHeight: "350px",
      height: "360px",
      boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
      },
    },
  },
  root: {
    border: "1px solid #00ff00",

    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",
    display: "flex",
    "@media(minWidth: 780px)": {
      flexDirection: "column",
      alignItems: "center",
      // display: ""
    },
  },
  media: {
    paddingTop: "45.50%",
    backgroundSize: "contain",
  },
  inputsearch: {
    flex: 1,
    width: "100%",
    "@media(minWidth: 780px)": {
      width: "100%",
    },
  },
  dropdown: {
    padding: 20,
    // '@media(minWidth: 780px)': {
    //   flexDirection: "row-reverse"
    // }
  },
  appBarSpacer: theme.mixins.toolbar,
  cardFlow: {
    justify: "center",
    alignItems: "stretch",
    "@media(minWidth: 780px)": {
      justifyContent: "flex-end",
      alignItems: "flex-start",
      flexDirection: "row-reverse",
    },
  },
  item: {
    margin: theme.spacing(1),
    "@media(minWidth:780px)": {},
  },
  flex: {
    flex: 1,
  },
  lefticon: {
    marginLeft: theme.spacing(4),
  },
  cardAction: {
    paddingBlock: 20,
    display: "block",
    width: "100%",
    height: "100%",
  },
  containerCard: {
    padding: "60px",
  },
}));

interface Isedekah {
  agency: string;
  name: string;
  negeri: string;
  daerah: string;
  image: string;
}

interface Props {
  agencyLists: Isedekah[];
  user: string;
  qrcode: boolean;
}

const Cards = ({ agencyLists, user }: Props) => {
  const classes = useStyles();

  const proceed = (data: string) => {
    generateLink(data, user);
  };

  return (
    <Grid container spacing={2}>
      {agencyLists.map((item, i) => (
        <Grid key={i} item xs={12} sm={6} md={4}>
          <Card className={classes.gridRoot}>
            <CardActionArea
              className={classes.cardAction}
              onClick={() => proceed(item.agency)}
            >
              <CardMedia image={item.image} className={classes.media} />

              <CardContent className={classes.cardContent}>
                <Grid container spacing={6}>
                  <Grid item xs={12} sm={12} className={classes.typography}>
                    <Typography
                      className={"MuiTypography--heading"}
                      variant={"h6"}
                      component={"header"}
                      gutterBottom
                      align="center"
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      className={"MuiTypography--subheading"}
                      variant={"subtitle1"}
                      component={"caption"}
                      // component="body"
                    >
                      {item.daerah} {item.negeri}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
