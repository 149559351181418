import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  Card,
  CardContent,
  // CardActionArea,
  makeStyles,
  Typography,
  // CardMedia,
} from "@material-ui/core";
// import { generateLink } from "./utils";
// import { relative } from "path";

const useStyles = makeStyles((theme) => ({
  mainroot: {
    padding: "20px !important",
  },
  containerCenter: {
    justifyContent: "center",
    paddingTop: 20,
    "@media(minWidth: 780px)": {},
  },
  image: {
    width: theme.spacing(15),
    margin: theme.spacing(1),
  },
  cardContent: {
    justifyContent: "center",
    maxwidth: "350px",
    "@media(minWidth: 780px)": {
      maxwidth: "350px",
      justifyContent: "center",
    },
  },
  typography: {
    justifyContent: "center",
    alignItems: "center",
    justifyItems: "center",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    textAlign: "center",
    margin: "10px",
  },
  gridRoot: {
    height: "400px",
    // minHeight: "350px",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
    },
    "@media(minWidth: 780px)": {
      // minHeight: "350px",
      height: "360px",
      boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
      "&:hover": {
        boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)",
      },
    },
  },
  root: {
    border: "1px solid #00ff00",

    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    flexDirection: "column",
    display: "flex",
    "@media(minWidth: 780px)": {
      flexDirection: "column",
      alignItems: "center",
      // display: ""
    },
  },
  media: {
    paddingTop: "45.50%",
    backgroundSize: "contain",
  },
  inputsearch: {
    flex: 1,
    width: "100%",
    "@media(minWidth: 780px)": {
      width: "100%",
    },
  },
  dropdown: {
    padding: 20,
    // '@media(minWidth: 780px)': {
    //   flexDirection: "row-reverse"
    // }
  },
  appBarSpacer: theme.mixins.toolbar,
  cardFlow: {
    justify: "center",
    alignItems: "stretch",
    "@media(minWidth: 780px)": {
      justifyContent: "flex-end",
      alignItems: "flex-start",
      flexDirection: "row-reverse",
    },
  },
  item: {
    margin: theme.spacing(1),
    "@media(minWidth:780px)": {},
  },
  flex: {
    flex: 1,
  },
  lefticon: {
    marginLeft: theme.spacing(4),
  },
  cardAction: {
    paddingBlock: 20,
    display: "block",
    width: "100%",
    height: "100%",
  },
  containerCard: {
    padding: "60px",
  },
  link: {
    fontFamily: "monospace",
  },
}));

interface Isedekah {
  agency: string;
  name: string;
  negeri: string;
  daerah: string;
  image: string;
}

interface Props {
  agencyLists: Isedekah[];
  user: string;
  qrcode: boolean;
}

const QRCodeCards = ({ agencyLists, user }: Props) => {
  const classes = useStyles();

  const getLink = (item: Isedekah): string => {
    let name = item.name.replace("/", " ");
    return (
      "https://snapnpay.my/pay/" + item.agency + "#name=" + encodeURI(name)
    );
  };
  const generateQRcode = (item: Isedekah): string => {
    // return "https://assets.snapnpay.my/images/SNAPNPAY.jpg";
    // return "https://snapnpay.my/qr2/?q="+encodeURIComponent("https://isedekah.snapnpay.my/?agency="+item.agency);
    // return "https://snapnpay.my/qr2/?black=2&q="+encodeURIComponent("https://snapnpay.my/pay/"+item.agency+"#name="+encodeURI(name));
    return "https://pay.tulus.my/qr2/?q=" + encodeURIComponent(getLink(item));
  };

  // const proceed = (data: string) => {
  //   generateLink(data, user);
  // };

  return (
    <Grid container spacing={2}>
      {agencyLists.map((item, i) => (
        <Grid key={i} item xs={12} sm={6} md={4}>
          <Card className={classes.gridRoot}>
            {/* <CardMedia image={item.image} className={classes.media} /> */}
            {/* {qrcode && <CardMedia image={generateQRcode(item)} className={classes.media} />} */}

            <CardContent className={classes.cardContent}>
              <Grid container justify="center">
                <img
                  src={generateQRcode(item)}
                  style={{
                    height: 256,
                    maxWidth: 256,
                    display: "block",
                    position: "absolute",
                  }}
                  alt={""}
                />
                <img
                  src={item.image}
                  style={{
                    maxHeight: 64,
                    maxWidth: 64,
                    display: "block",
                    position: "absolute",
                    marginTop: 64+32,
                  }}
                  alt={""}
                />
                <div style={{ height: 256, maxWidth: 256 }}></div>
              </Grid>
              <Typography
                className={"MuiTypography--heading"}
                variant={"h6"}
                component={"header"}
                gutterBottom
                align="center"
              >
                {item.name}
              </Typography>
              <div style={{fontFamily: "monospace", wordWrap: "normal", whiteSpace: "normal", wordBreak: "break-word"}}>
                {getLink(item)}
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default QRCodeCards;
