import React from "react";
import "typeface-roboto";
import Form from "./components/Form";
import * as firebase from "firebase/app";
import "firebase/auth";
import withFirebaseAuth, {
  WrappedComponentProps,
} from "react-with-firebase-auth";
import { firebaseConfig } from "./config";
import Login from "./components/Login";
import {
  checkUserType,
  getSearchTerm,
  generateLink,
  getEmail,
  getQRCode,
} from "./components/utils";
import { agencies } from "./data";

let browser = navigator.userAgent;
console.log("browser", browser);

const App = ({ signInWithGoogle, signOut, user }: WrappedComponentProps) => {
  let searchterm = getSearchTerm();

  console.log("userType", checkUserType());
  if (checkUserType()) {
    // Explicit email given
    let email = getEmail();
    if (email !== "") {
      if (searchterm !== "") {
        // for (let i = 0; i < agencies.length; i++) {
        //   if (agencies[i].agency === searchterm) {
        // Exact match, just redirect it.
        generateLink(searchterm, email);
        //   }
        // }
      }
    }
    return (
      <Form
        signOut={signOut}
        user={""}
        searchterm={searchterm}
        qrcode={false}
      />
    );
  } else {
    // No email given on URL
    if (user) {
      console.log("user", user);
      console.log("searchterm", searchterm);

      if (searchterm !== "") {
        // for (let i = 0; i < agencies.length; i++) {
        //   if (agencies[i].agency === searchterm && user.email !== null) {
        // Exact match, just redirect it.
        if (user.email) {
          generateLink(searchterm, user.email);
        }
        //   }
        // }
      }
      let qrcode = getQRCode();
      return (
        <Form
          signOut={signOut}
          user={user.email}
          searchterm={searchterm}
          qrcode={qrcode}
        />
      );
    }

    return <Login signInWithGoogle={signInWithGoogle} />;
  }
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);

// const App = () => {
//   let display = <Form />;

//   return <div>{display}</div>;
// };

// export default App;
