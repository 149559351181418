import moment from "moment/moment";
import { v4 as uuid } from "uuid";

export const generateLink = (data: string, user: string) => {
  let email = parseUrl(user);
  console.log("email", email);

  const root = "https://prod.snapnpay.co/payments/mobile/checkout/";

  const ts = moment().format("YYYYMMDDHHmmssSSS");
  let refNo = `${ts}/0/${data}`;
  let appName = "https://isedekah.snapnpay.my/";
  let source = "web";
  let ctxID = uuid();

  let thisUrl = window.location.href;

  const path = `${root}${refNo}/${email}?app_version=${ts}&app_name=${encodeURIComponent(
    appName
  )}&source=${encodeURIComponent(source)}&ctx_id=${encodeURIComponent(ctxID)}&returnUrl=${encodeURIComponent(thisUrl)}`;

  console.log(path);

  if (path === "") {
    alert("Sila pastikan maklumat yang diisikan adalah lengkap");
  } else {
    document.location.href = path;
  }
};

export const checkUserType = () => {
  const url = new URL(document.location.toString());

  console.log("url", url);
  if (url.search) {
    if (url.search.indexOf("email") !== -1) {
      return true;
    }
  }
  return false;
};

const parseUrl = (user: string) => {
  const url = new URL(document.location.toString());

  console.log("url pass", url);
  console.log("user", user);
  if (user.length > 0) {
    return user;
  } else {
    console.log("url", url);
    let paths = url.search.split("?").filter((item) => {
      if (item === "") {
        return null;
      }
      return item;
    });
    console.log("urllala", paths[0]);
    let path = paths[0].split("=");

    console.log("urllala", path);

    return path[1];
  }
};

// angch: ... I hacked a redundant way to get search term
export const getSearchTerm = () => {
  let s = window.location.search;

  let getparams = new Map();
  if (s.length > 0) {
    let k = s.slice(1, s.length).split("&");
    for (let i = 0; i < k.length; i++) {
      let kv = k[i].split("=");
      if (kv.length === 2) {
        console.log("kv[0]", kv[0]);
        console.log("kv[0]", kv[1]);

        getparams.set(kv[0], kv[1]);
      }
    }
  }
  let searchTerm = "";
  if (getparams.get("agency") !== undefined) {
    searchTerm = getparams.get("agency");
  }
  return searchTerm;
};

export const getQRCode = (): boolean => {
  let s = window.location.search;
  let getparams = new Map();
  if (s.length > 0) {
    let k = s.slice(1, s.length).split("&");
    let i = 0;
    for (i = 0; i < k.length; i++) {
      let kv = k[i].split("=");
      if (kv.length === 2) {
        getparams.set(kv[0], kv[1]);
      }
    }
  }
  let qrcode = false;
  if (getparams.get("qrcode") !== undefined) {
    qrcode = true;
  }
  return qrcode;
};

export const getEmail = () => {
  let s = window.location.search;
  let getparams = new Map();
  if (s.length > 0) {
    let k = s.slice(1, s.length).split("&");
    let i = 0;
    for (i = 0; i < k.length; i++) {
      let kv = k[i].split("=");
      if (kv.length === 2) {
        getparams.set(kv[0], kv[1]);
      }
    }
  }
  let searchTerm = "";
  if (getparams.get("email") !== undefined) {
    searchTerm = getparams.get("email");
  }
  return searchTerm;
};
